<div class="mat-dialog-container">
  <div class="header-container">
    <h1 mat-dialog-title #dialogTitle>Edit Domain</h1>
  </div>

  <mat-dialog-content>
    <form [formGroup]="editDomainForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">Domain URL:</mat-label>
          <mat-form-field appearance="outline" autofocus>
            <input matInput type="text" formControlName="url" placeholder="Enter domain URL" [(ngModel)]="domainUrl" required>
            <mat-error *ngIf="editDomainForm.get('url').invalid && editDomainForm.get('url').touched">
              Invalid URL format
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-label class="form-field-label">Thumbnail:</mat-label>
          <div class="form-field form-field-input dropzone"
               (drop)="onDrop($event)"
               (dragover)="onDragOver($event)"
               (dragenter)="onDragEnter($event)"
               (dragleave)="onDragLeave($event)">
            <div class="dropzone-content">
              <span class="dropzone-text" *ngIf="!fileName">
                Drag & drop to upload or
                <button class="mat-button file-select-btn drag-over-btn" mat-button type="button" (click)="fileInput.click()">Select image</button>
              </span>
              <span *ngIf="fileName">{{ fileName }}</span>
              <input #fileInput id="fileInput" type="file" (change)="onFileSelected($event)" accept="image/*" hidden>
            </div>
            <mat-error *ngIf="editDomainForm.get('thumbnail').invalid && editDomainForm.get('thumbnail').touched">
              Please select a valid image file.
            </mat-error>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button (click)="onDelete()" class="btn btn-red remove-btn">
      <mat-icon class="bin-icon">delete</mat-icon>
      Remove
    </button>
    <button mat-button (click)="onCancel()" class="btn btn-blue cancel-btn">Cancel</button>
    <button mat-raised-button type="submit" [disabled]="editDomainForm.invalid || isLoading" class="btn btn-green submit-btn" (click)="onSubmit()">
      <span *ngIf="!isLoading">Save Changes</span>
      <div *ngIf="isLoading" class="spinner-container">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
    </button>
  </mat-dialog-actions>
</div>
