<app-header></app-header>

<div class="main-wrapper">
  <div class="sidebar-wrapper">
    <app-sidebar></app-sidebar>
  </div>

  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>

