import {Injectable} from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {

  }

  handleError(errorMessage: string) {
    this.snackBar.open(errorMessage, 'Close', {
      duration: 6000
    });
  }

  showSnackbar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 4000
    });
  }

}
