import {Component} from '@angular/core';
import {MembersService} from '../../services/members.service';
import {FormControl, FormGroup, Validators,} from '@angular/forms';
import {EMAIL_REGEX} from '../../helpers/regex'
import {MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../services/snackbar.service'

@Component({
  selector: 'prism-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent {
  emailFormGroup: FormGroup;

  constructor(private memberService: MembersService,
              public dialogRef: MatDialogRef<AddMemberComponent>,
              private snackbarService: SnackbarService) {
    this.emailFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
    });

  }

  onSubmit() {
    const memberData = this.emailFormGroup.value;
    this.memberService.addMember(memberData).subscribe(
      response => {
        this.snackbarService.showSnackbar('Member added successfully', 'success');
      });

    this.dialogRef.close();
  }
}
