import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable, EMPTY} from 'rxjs';
import {AuthenticationService} from '../authentication.service';
import {PrismHeaders} from '../../enums';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../../environments';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public authService: AuthenticationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers;
    const serviceToken = this.authService.sessionTokenRaw;

    if (!req.headers.has(PrismHeaders.SkipHeaders)) {
      if (!serviceToken) {
        return from(this.authService.sessionToken$)
          .pipe(
            switchMap(token => {
              if (!!token) {
                headers = req.headers
                  .set('Content-Type', 'application/json')
                  .set('Access-Control-Allow-Origin', `${environment.apiBaseUrl}`)
                  .set(PrismHeaders.Authorization, `Bearer ${token}`);

                const reqClone = req.clone({
                  headers
                });

                return next.handle(reqClone);
              } else {
                return EMPTY;
              }
            })
          );
      } else {
        headers = req.headers
          .set('Content-Type', 'application/json')
          .set('Access-Control-Allow-Origin', `${environment.apiBaseUrl}`)
          .set(PrismHeaders.Authorization, `Bearer ${serviceToken}`);

        const reqClone = req.clone({
          headers
        });

        return next.handle(reqClone);
      }

    } else {
      headers = req.headers
        .delete(PrismHeaders.SkipHeaders);

      const reqClone = req.clone({
        headers
      });

      return next.handle(reqClone);
    }
  }
}
