import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {SnackbarService} from '../../services/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProcessorsService} from "../../services/processors.service";

@Component({
  selector: 'prism-edit-processor',
  templateUrl: './edit-processor.component.html',
  styleUrls: ['./edit-processor.component.scss']
})
export class EditProcessorComponent implements OnInit, OnDestroy {
  freshUntouchedProfileData: any;
  processorDataState: any;
  private processorDataSub: Subscription;
  private formChangeSub: Subscription;
  formChanged: BehaviorSubject<boolean>;
  processorProfileFormGroup: FormGroup<ProcessorProfileFormGroup>;
  isLoading: boolean;
  updatingProcessor: boolean;

  constructor(private processorsService: ProcessorsService,
              private formBuilder: UntypedFormBuilder,
              private snackbarService: SnackbarService,
              private dialogRef: MatDialogRef<EditProcessorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.formChanged = new BehaviorSubject<boolean>(true);
    this.processorProfileFormGroup = this.formBuilder.group({
      imageService: new FormControl(false, [Validators.required]),
      videoService: new FormControl(false, [Validators.required]),
      floorplanService: new FormControl(false, [Validators.required]),
      invoicingPermissions: new FormControl(false, [Validators.required]),
      costViewingPermissions: new FormControl(false, [Validators.required]),
    });
    this.isLoading = true;
    this.updatingProcessor = false;
  }

  ngOnInit() {
    this.processorDataSub = this.processorsService.getProcessor(this.data).subscribe(
      (responseData: any) => {
        this.isLoading = false;
        this.processorDataState = responseData.data;
        this.populateFields(responseData.data)
      },
      (error: Error) => {
        this.closeDialog()
        console.error(error)
        this.snackbarService.handleError("This is not an authenticated member.")
      },
      () => {
      }
    )
    this.formChangeSub = this.processorProfileFormGroup.valueChanges.subscribe((data) => {

      let {
        imageService,
        videoService,
        floorplanService,
        invoicingPermissions,
      } = this.processorDataState
      if (data.imageService === imageService && data.videoService === videoService && data.floorplanService === floorplanService && data.invoicingPermissions === invoicingPermissions) {
        this.formChanged.next(false);
      } else {
        this.formChanged.next(true);
      }
    })
  }

  closeDialog() {
    this.dialogRef.close({});
  }

  save({servicePermissions, invoicingPermissions, costViewingPermissions}: {
    servicePermissions: ServicePermissions,
    invoicingPermissions: boolean,
    costViewingPermissions: boolean
  }) {
    this.dialogRef.close({
      servicePermissions,
      invoicingPermissions,
      costViewingPermissions
    });
  }

  saveProcessor() {
    this.updatingProcessor = true;
    this.snackbarService.showSnackbar('Updating processor profile permissions.')
    this.processorProfileFormGroup.disable();
    const servicePermissions = {
      imageService: this.personalRawFormData.imageService,
      videoService: this.personalRawFormData.videoService,
      floorplanService: this.personalRawFormData.floorplanService,
    }
    const invoicingPermissions = this.personalRawFormData.invoicingPermissions;
    const costViewingPermissions = this.personalRawFormData.costViewingPermissions;

    this.processorsService.updateProcessorPermissions({
      servicePermissions,
      invoicingPermissions,
      costViewingPermissions,
      uid: this.processorDataState.uid
    })
      .subscribe(() => {
        this.save({servicePermissions, invoicingPermissions, costViewingPermissions})
        this.snackbarService.showSnackbar('Processor permissions has been updated!');
      });
  }

  populateFields(processorProfile: ProcessorProfile) {
    const {
      firstName,
      lastName,
      servicePermissions,
      invoicingPermissions,
      costViewingPermissions,
    } = processorProfile;

    this.freshUntouchedProfileData = {
      firstName,
      lastName,
      servicePermissions,
      invoicingPermissions,
      costViewingPermissions
    };

    this.imageServicesFormControl.setValue(servicePermissions.imageService);
    this.videoServicesFormControl.setValue(servicePermissions.videoService);
    this.floorplanServicesFormControl.setValue(servicePermissions.floorplanService);
    this.invoicingPermissionsFormControl.setValue(invoicingPermissions);
    this.costViewingPermissionsFormControl.setValue(costViewingPermissions);

    this.processorProfileFormGroup.updateValueAndValidity();
    this.processorProfileFormGroup.enable();
  }

  get invoicingPermissionsFormControl() {
    return this.processorProfileFormGroup.get('invoicingPermissions') as FormControl<boolean>;
  }

  get costViewingPermissionsFormControl() {
    return this.processorProfileFormGroup.get('costViewingPermissions') as FormControl<boolean>;
  }

  get imageServicesFormControl() {
    return this.processorProfileFormGroup.get('imageService') as FormControl<boolean>;
  }

  get videoServicesFormControl() {
    return this.processorProfileFormGroup.get('videoService') as FormControl<boolean>;
  }

  get floorplanServicesFormControl() {
    return this.processorProfileFormGroup.get('floorplanService') as FormControl<boolean>;
  }

  get personalRawFormData() {
    return this.processorProfileFormGroup.getRawValue();
  }

  ngOnDestroy() {
    this.formChangeSub.unsubscribe();
    this.processorDataSub.unsubscribe();
  }
}

export interface ProcessorProfile {
  id?: string;
  firstName: string;
  lastName: string;
  loginEmail: string;
  acceptedPolicies?: boolean;
  servicePermissions?: ServicePermissions;
  invoicingPermissions?: boolean;
  costViewingPermissions?: boolean;
  disabled?: boolean;
}

export interface ServicePermissions {
  videoService: boolean;
  imageService: boolean;
  floorplanService: boolean;
}

export interface ProcessorProfileFormGroup {
  videoService: FormControl<boolean>;
  imageService: FormControl<boolean>;
  floorplanService: FormControl<boolean>;
  invoicingPermissions: FormControl<boolean>;
  costViewingPermissions: FormControl<boolean>;
}
