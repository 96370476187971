import {BehaviorSubject, throwError} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments';
import {PrismHeaders} from '../enums';

@Injectable({
  providedIn: 'root'
})
export class WhitelistService {
  constructor(private http: HttpClient) {
  }

  private fetchingDomains: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private fetchingDomainCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _domainsListPaginationData: BehaviorSubject<any> = new BehaviorSubject<any>({
    pageSize: 10,
    pageIndex: 0
  });

  fetchDomainsFromDatabase() {
    const paginationData = this._domainsListPaginationData.value;
    const headers = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify(paginationData));
    this.http.get(environment.apiBaseUrl + '/GetWhitelist', {headers})
      .pipe(
        take(1),
        map((Response: any) => {
          return Response.data.map((data: any) => {
            return {
              domain: data.domain
            }
          })
        })
      )
      .subscribe((data: any[]) => {
        this.fetchingDomains.next(data);
      });
  }


  addDomain(domainUrl: string, thumbnailBase64: string | null) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = {url: domainUrl, thumbnailBase64};
    return this.http.post(environment.apiBaseUrl + '/PostWhitelist', body, {headers}).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  updateDomain(domain: any) {
    return this.http.put(environment.apiBaseUrl + '/PutWhitelist', domain)
      .pipe(
        take(1)
      )
  }

  getDomainsListUpdateListener$() {
    return this.fetchingDomains.asObservable();
  }

  getDomainsCountData(): void {
    this.http.get(environment.apiBaseUrl + '/GetWhitelistDomainCount')
      .pipe(
        take(1),
        map((response: any) => response.data),
        catchError(error => {
          console.error('Error fetching whitelist domain count:', error);
          return throwError('Failed to fetch whitelist domain count');
        })
      )
      .subscribe((data) => {
        this.fetchingDomainCount.next(data);
      });
  }

  getDomainsCountListener$() {
    return this.fetchingDomainCount.asObservable();
  }

  updateDomainsListPagination({pageSize, pageIndex}: any) {
    this._domainsListPaginationData.next({pageSize, pageIndex});
  }

  private handleError(error: any) {
    console.error('Error:', error);
    return throwError(error);
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result?.toString().split(',')[1];
        if (result) {
          resolve(result);
        } else {
          reject(new Error('Failed to convert file to base64'));
        }
      };
      reader.readAsDataURL(file);
    });
  }

  deleteDomain(domain) {
    const body = {domain};
    return this.http.delete(environment.apiBaseUrl + '/DeleteWhitelist', {body})
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }
}
