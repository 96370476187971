import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UiModule} from './ui.module';
import {RouterModule} from '@angular/router';
import {HomeCoreRouting} from '../../common/routing/home-core.routing';
import {MatTableModule} from '@angular/material/table'
import {MatPaginatorModule} from '@angular/material/paginator';
import {MembersListComponent} from '../../components/members-list/members-list.component';
import {ProcessorsListComponent} from '../../components/processors-list/processors-list.component';
import {MemberDownloadsComponent} from '../../components/member-downloads/member-downloads.component';
import {TotalInvoiceComponent} from '../../components/invoice-totals/total-invoices.component';
import {DropzoneDirective} from '../../directives/dropzone.directive';
import {SharedModule} from "./shared.module";
import {AddMemberComponent} from "../../components/add-member/add-member.component";
import {EditMemberComponent} from "../../components/edit-member/edit-member.component";
import {MemberStateComponent} from "../../components/member-state/member-state.component";
import {AddProcessorComponent} from "../../components/add-processor/add-processor.component";
import {EditProcessorComponent} from "../../components/edit-processor/edit-processor.component";
import {XeroProductsListComponent} from "../../components/xero-products-list/xero-products-list.component";
import {WhitelistDomainListComponent} from "../../components/whitelist-domain-list/whitelist-domain-list.component";
import {AddWhitelistDomainComponent} from "../../components/add-whitelist-domain/add-whitelist-domain.component";
import {EditDomainDialogComponent} from "../../components/edit-domain-dialog/edit-domain-dialog.component";

@NgModule({
  imports: [
    RouterModule,
    UiModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    SharedModule,
    HomeCoreRouting
  ],
  declarations: [
    MembersListComponent,
    XeroProductsListComponent,
    ProcessorsListComponent,
    MemberDownloadsComponent,
    DropzoneDirective,
    TotalInvoiceComponent,
    DropzoneDirective,
    AddMemberComponent,
    AddProcessorComponent,
    EditMemberComponent,
    EditProcessorComponent,
    MemberStateComponent,
    WhitelistDomainListComponent,
    AddWhitelistDomainComponent,
    EditDomainDialogComponent
  ]
})

export class HomeCoreModule {
}
