<mat-toolbar class="header">
  <div class="header-left">
    <div class="logo" [routerLink]="['/home/jobs']">
      <img src="../../../assets/images/bwrm-new-logo.png" alt="BWRM">
    </div>

  </div>
  <div class="header-right">
    <div class="no-connection-logo-container"></div>
    <prism-xero-display class="xero-display slide-in-btn"></prism-xero-display>
    <button class="btn" mat-raised-button color="primary" (click)="onLogout()">Log Out</button>
  </div>
</mat-toolbar>
