<div class="sidebar">
  <div class="sidebar-toggle">
    <div class="sidebar-toggle-item">
      <img [src]="'../../../assets/icons/sidebar/home-active.svg'" alt="">
    </div>
  </div>

  <div class="sidebar-menu-wrapper">
    <ul class="sidebar-menu">
      <li class="sidebar-menu-item"
          routerLinkActive="active"
          [routerLink]="['/home', 'members']">
        Members
      </li>
      <li class="sidebar-menu-item"
          routerLinkActive="active"
          [routerLink]="['/home', 'processors']">
        Processors
      </li>
      <li class="sidebar-menu-item"
          routerLinkActive="active"
          [routerLink]="['/home', 'products']">
        Products
      </li>
      <li class="sidebar-menu-item"
          routerLinkActive="active"
          [routerLink]="['/home/agreements']">
        Agreements
      </li>
      <li class="sidebar-menu-item"
          routerLinkActive="active"
          [routerLink]="['/home/download-data']">
        Member Downloads
      </li>
      <li class="sidebar-menu-item"
          routerLinkActive="active"
          [routerLink]="['/home/total-invoices']">
        Invoice Totals
      </li>
      <li class="sidebar-menu-item"
          routerLinkActive="active"
          [routerLink]="['/home', 'whitelist']">
        Whitelist Domain
      </li>
    </ul>
  </div>

</div>
