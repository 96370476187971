import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AddWhitelistDomainComponent} from '../add-whitelist-domain/add-whitelist-domain.component';
import {EditDomainDialogComponent} from '../edit-domain-dialog/edit-domain-dialog.component';
import {WhitelistService} from '../../services/whitelist.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-whitelist-domain-list',
  templateUrl: './whitelist-domain-list.component.html',
  styleUrls: ['./whitelist-domain-list.component.scss']
})
export class WhitelistDomainListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['domain', 'edit'];
  public fetchingDomains$: Observable<any>;
  public fetchingDomainsCount$: Observable<any>;
  public isLoading = false;
  private domainSub: Subscription;
  private domainCountSub: Subscription;
  dataSource = new MatTableDataSource<any>([]);
  paginatorControl = {
    length: 0,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 40, 50, 100]
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private whitelistService: WhitelistService,
    private dialog: MatDialog,
  ) {
    this.fetchingDomains$ = this.whitelistService.getDomainsListUpdateListener$();
    this.fetchingDomainsCount$ = this.whitelistService.getDomainsCountListener$();
    this.isLoading = false;
  }

  ngOnInit() {
    this.isLoading = true;
    this.whitelistService.getDomainsCountData();
    this.domainCountSub = this.fetchingDomainsCount$.subscribe(count => {
      this.paginatorControl.length = count;
    });
    this.showDomains();
    this.isLoading = true;
  }

  paginate(pageEvent: PageEvent) {
    const {pageSize, pageIndex} = pageEvent;
    this.whitelistService.updateDomainsListPagination({pageSize, pageIndex});
    this.isLoading = true;
    this.whitelistService.fetchDomainsFromDatabase();
  }

  showDomains() {
    this.whitelistService.fetchDomainsFromDatabase();
    this.isLoading = true;
    this.domainSub = this.fetchingDomains$.subscribe(domainList => {
      if (Array.isArray(domainList)) {
        this.isLoading = false;
        this.dataSource.data = domainList;
      }
    });
  }

  addDomain() {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      autoFocus: true,
      width: '400px'
    };
    const dialogRef = this.dialog.open(AddWhitelistDomainComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = true;
        this.whitelistService.getDomainsCountData();
        this.whitelistService.fetchDomainsFromDatabase();
      }
    });
  }

  editDomain(domain: any) {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      autoFocus: true,
      width: '400px',
      data: {domain}
    };

    const dialogRef = this.dialog.open(EditDomainDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = true;
        this.whitelistService.getDomainsCountData();
        this.whitelistService.fetchDomainsFromDatabase();
      }
    });
  }

  ngOnDestroy() {
    if (this.domainSub) {
      this.domainSub.unsubscribe();
    }
    if (this.domainCountSub) {
      this.domainCountSub.unsubscribe();
    }
  }

}
