<div class="container">
  <div class="agreement-option">
    <h4>Update Agreements:</h4>
    <button [ngClass]="(agreementType === 'platform') ? 'agreement-active': 'agreement-inactive'"
            (click)="agreementToggle('platform')" mat-raised-button color="primary" id="platform" #platformUploadButton>
      Update Platform Agreement
    </button>
  </div>
  <div class="dropzone"
       dropzone
       (hovered)="toggleHover($event)"
       (dropped)="onDrop($event)"
       (click)="onClick()"
       [class.hovering]="isHovering">

    <p *ngIf="!(fileUploaded | async)">Drag and Drop a File</p>
    <p *ngIf="!(fileUploaded | async)">Upload {{agreementType}} agreement pdf file</p>
    <p *ngIf="fileUploaded | async"> Upload {{file.name}}</p>
  </div>
  <div class="upload-action" *ngIf="fileUploaded | async">
    <div>
      <button mat-raised-button color="primary" (click)="startUpload()">Upload</button>
    </div>
    <div>
      <button mat-raised-button color="warn" (click)="cancelUpload()" [ngStyle]="{'color': 'white' }">Cancel</button>
    </div>
  </div>
</div>

