import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import {BehaviorSubject} from 'rxjs';
import {MembersService} from 'src/app/services/members.service';
import {saveAs} from 'file-saver';
import {SnackbarService} from '../../services/snackbar.service';
import * as _ from "lodash";

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'prism-member-downloads',
  templateUrl: './member-downloads.component.html',
  styleUrls: ['./member-downloads.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS
    },
  ]
})

export class MemberDownloadsComponent implements OnInit {
  downloadsDateStart: any = {};
  downloadsDateEnd: any = {};
  statisticsDateStart: any = {};
  statisticsDateEnd: any = {};
  downloadsForm: FormGroup;
  statisticsForm: FormGroup;
  startDateChanged: BehaviorSubject<boolean>;
  endDateChanged: BehaviorSubject<boolean>;

  constructor(private formBuilder: FormBuilder, private memberService: MembersService, private snackbarService: SnackbarService) {
    this.downloadsDateStart = {};
    this.downloadsDateEnd = {};
    this.startDateChanged = new BehaviorSubject<boolean>(true);
    this.endDateChanged = new BehaviorSubject<boolean>(true);
  }

  ngOnInit() {
    const defaultStartDate = moment().startOf('month');
    const defaultEndDate = moment().endOf('month');

    this.downloadsForm = this.formBuilder.group({
      startDate: new FormControl(defaultStartDate, [Validators.required]),
      endDate: new FormControl(defaultEndDate, [Validators.required])
    });

    this.statisticsForm = this.formBuilder.group({
      startDate: new FormControl(defaultStartDate, [Validators.required]),
      endDate: new FormControl(defaultEndDate, [Validators.required])
    });

    this.downloadsDateStart = {
      date: defaultStartDate.date(),
      milliseconds: 0,
      seconds: 0,
      minutes: 0,
      hours: 0,
      day: defaultStartDate.days(),
      months: defaultStartDate.month(),
      years: defaultStartDate.year()
    };

    this.downloadsDateEnd = {
      date: defaultEndDate.date(),
      milliseconds: defaultEndDate.milliseconds(),
      seconds: defaultEndDate.seconds(),
      minutes: defaultEndDate.minutes(),
      hours: defaultEndDate.hours(),
      day: defaultEndDate.days(),
      months: defaultEndDate.month(),
      years: defaultEndDate.year()
    };

    this.statisticsDateStart = {
      date: defaultStartDate.date(),
      milliseconds: 0,
      seconds: 0,
      minutes: 0,
      hours: 0,
      day: defaultStartDate.days(),
      months: defaultStartDate.month(),
      years: defaultStartDate.year()
    };

    this.statisticsDateEnd = {
      date: defaultEndDate.date(),
      milliseconds: defaultEndDate.milliseconds(),
      seconds: defaultEndDate.seconds(),
      minutes: defaultEndDate.minutes(),
      hours: defaultEndDate.hours(),
      day: defaultEndDate.days(),
      months: defaultEndDate.month(),
      years: defaultEndDate.year()
    };
  }

  JSONToCSVConvertor(JSONData: any, ShowLabel: boolean) {
    let row;
    const arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    let CSV = '';
    if (ShowLabel) {
      row = "";
      for (let index in arrData[0]) {
        row += index + ',';
      }
      row = row.slice(0, -1);
      CSV += row + '\r\n';
    }
    for (let i = 0; i < arrData.length; i++) {
      row = "";
      for (let index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }
      row.slice(0, row.length - 1);
      CSV += row + '\r\n';
    }
    if (CSV == '') {
      alert("Invalid data");
      return;
    }
    let csv = CSV;
    let blob = new Blob([csv], {type: 'text/csv'});
    const objectUrl = window.URL.createObjectURL(blob);
    saveAs(objectUrl, 'download-report.csv');
  }

  getDownloadData() {
    if (_.isEmpty(this.downloadsDateStart) || _.isEmpty(this.downloadsDateEnd)) {
      this.snackbarService.handleError("Please select a valid date range.")
    } else if (this.downloadsDateStart.years < this.downloadsDateEnd.years) {
      this.memberService.getDownloadData(this.downloadsDateStart, this.downloadsDateEnd).subscribe((responseData: any) => {
        if (responseData.code !== 200) {
          this.snackbarService.showSnackbar(responseData.message);
        } else {
          this.snackbarService.showSnackbar(responseData.message);
          this.JSONToCSVConvertor(responseData.data, true);
        }
      })
    } else if (this.downloadsDateStart.years === this.downloadsDateEnd.years) {
      if (this.downloadsDateStart.months <= this.downloadsDateEnd.months) {
        this.memberService.getDownloadData(this.downloadsDateStart, this.downloadsDateEnd).subscribe((responseData: any) => {
          if (responseData.code !== 200) {
            this.snackbarService.showSnackbar(responseData.message);
          } else {
            this.snackbarService.showSnackbar(responseData.message);
            this.JSONToCSVConvertor(responseData.data, true);
          }
        })
      }
    } else {
      this.snackbarService.handleError("Please select a valid date range.")
    }
  }

  getStatisticsData() {
    this.snackbarService.showSnackbar('Fetching member statistics data.')
    if (_.isEmpty(this.statisticsDateStart) || _.isEmpty(this.statisticsDateEnd)) {
      this.snackbarService.handleError("Please select a valid date range.")
    } else if (this.statisticsDateStart.years < this.statisticsDateEnd.years) {
      this.memberService.getStatisticsData(this.statisticsDateStart, this.statisticsDateEnd).subscribe((responseData: any) => {
        if (responseData.code !== 200) {
          this.snackbarService.showSnackbar(responseData.message);
        } else {
          this.snackbarService.showSnackbar(responseData.message);
          this.JSONToCSVConvertor(responseData.data, true);
        }
      })
    } else if (this.statisticsDateStart.years === this.statisticsDateEnd.years) {
      if (this.statisticsDateStart.months <= this.statisticsDateEnd.months) {
        this.memberService.getStatisticsData(this.statisticsDateStart, this.statisticsDateEnd).subscribe((responseData: any) => {
          if (responseData.code !== 200) {
            this.snackbarService.showSnackbar(responseData.message);
          } else {
            this.snackbarService.showSnackbar(responseData.message);
            this.JSONToCSVConvertor(responseData.data, true);
          }
        })
      }
    } else {
      this.snackbarService.handleError("Please select a valid date range.")
    }
  }

  chosenStartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, control: FormControl, formName: 'downloads' | 'statistics') {
    const ctrlValue = control.value;
    ctrlValue.month(normalizedMonth.month());
    control.setValue(ctrlValue);
    if (formName === 'downloads') {
      this.downloadsDateStart = control.value.toObject();
    } else if (formName === 'statistics') {
      this.statisticsDateStart = control.value.toObject();
    }
    this.startDateChanged.next(false)
    datepicker.close();
  }

  chosenStartYearHandler(normalizedYear: Moment, control: FormControl) {
    const ctrlValue = control.value;
    ctrlValue.year(normalizedYear.year());
    control.setValue(ctrlValue);
  }

  chosenEndMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, control: FormControl, formName: 'downloads' | 'statistics') {
    const ctrlValue = control.value;
    ctrlValue.month(normalizedMonth.month());
    control.setValue(ctrlValue);

    if (formName === 'downloads') {
      this.downloadsDateEnd = control.value.toObject();
    } else if (formName === 'statistics') {
      this.statisticsDateEnd = control.value.toObject();
    }

    this.endDateChanged.next(false)
    datepicker.close();
  }

  chosenEndYearHandler(normalizedYear: Moment, control: FormControl) {
    const ctrlValue = control.value
    ctrlValue.year(normalizedYear.year());
    control.setValue(ctrlValue);
  }

  get downloadsStartDateFormControl() {
    return this.downloadsForm.get('startDate') as FormControl;
  }

  get downloadsEndDateFormControl() {
    return this.downloadsForm.get('endDate') as FormControl;
  }

  get statisticsStartDateFormControl() {
    return this.statisticsForm.get('startDate') as FormControl;
  }

  get statisticsEndDateFormControl() {
    return this.statisticsForm.get('endDate') as FormControl;
  }
}
