import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {ServiceTypes, ServiceTypesEnum, XeroItem, XeroProductsService} from "../../services/xero-products.service";

@Component({
  selector: 'xero-products-list',
  templateUrl: './xero-products-list.component.html',
  styleUrls: ['./xero-products-list.component.scss'],
})

export class XeroProductsListComponent implements OnInit, OnDestroy {
  displayedColumns: string[];
  public xeroProducts$: Observable<any>;
  public xeroProductsCount$: Observable<any>;
  public isLoading = true;
  private processorSub: Subscription;
  private xeroProductsCountSub: Subscription;
  productServiceTypeSelected: { [x: string]: ServiceTypesEnum; };
  dataSource: any;
  paginatorControl: {
    length: number,
    pageSize: number,
    pageSizeOptions: number[]
  };

  constructor(private xeroProductsService: XeroProductsService) {
    this.displayedColumns = ['id', 'purchasesUnitPrice', 'salesUnitPrice', 'active', 'major', 'image', 'floorplan', 'video'];
    this.xeroProducts$ = this.xeroProductsService.xeroProducts$;
    this.xeroProductsCount$ = this.xeroProductsService.xeroProductsCount$;
    this.isLoading = true;
    this.dataSource = [];
    this.productServiceTypeSelected = {}
    this.paginatorControl = {
      length: 100,
      pageSize: 10,
      pageSizeOptions: [5, 10, 20, 40, 50, 100]
    };
  }

  ngOnInit() {
    this.isLoading = true;
    this.xeroProductsService.getXeroProductsCount()
    this.xeroProductsCountSub = this.xeroProductsCount$.subscribe(
      (count) => {
        this.paginatorControl.length = count;
      }
    )
    this.xeroProductsService.getXeroProducts();
    this.processorSub = this.xeroProducts$.subscribe(
      (xeroProducts) => {
        if (Array.isArray(xeroProducts) === true && xeroProducts.length > 0) {
          xeroProducts.forEach((product) => {
            if (product.serviceType) {
              this.productServiceTypeSelected[product.id] = product.serviceType;
            } else {
              this.productServiceTypeSelected[product.id] = null
            }
          })
          this.dataSource = xeroProducts;
          this.isLoading = false;
        }
      }
    )
  }

  updateXeroProductServiceType(xeroProduct: XeroItem, serviceType: ServiceTypes) {
    xeroProduct.disabled = true
    this.productServiceTypeSelected[xeroProduct.id] = serviceType
    this.xeroProductsService.updateXeroProductServiceType(xeroProduct.id, serviceType)
      .subscribe(() => {
        xeroProduct.disabled = false;
      }, (error) => {
        console.error(error)
        xeroProduct.disabled = false;
      })
  }

  updateXeroProductPriority(event, xeroProduct: XeroItem) {
    const checked = event.checked;
    xeroProduct.priority = checked ? 'major' : 'minor'
    this.xeroProductsService.putXeroProductPriority(xeroProduct).subscribe(() => null, (err) => {
      console.error(err)
      xeroProduct.priority = !checked ? 'major' : 'minor'
    })
  }

  updateXeroProductActiveStatus(currentState: boolean, xeroProduct: XeroItem) {
    xeroProduct.disabled = true;
    this.xeroProductsService.updateXeroProductActiveStatus(xeroProduct.id, !currentState)
      .subscribe(() => {
        xeroProduct.disabled = false;
      }, (error) => {
        console.error(error)
        xeroProduct.disabled = false;
      })
  }

  paginate(pageEvent: PageEvent) {
    const {pageSize, pageIndex} = pageEvent;
    this.xeroProductsService.updateXeroProductsListPagination({pageSize, pageIndex});
    this.isLoading = true;
    this.xeroProductsService.getXeroProducts();
  }

  ngOnDestroy() {
    this.processorSub.unsubscribe();
    this.xeroProductsCountSub.unsubscribe();
  }

  protected readonly ServiceTypesEnum = ServiceTypesEnum;
}


