import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {MembersService} from '../../services/members.service';
import {Observable} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {AddMemberComponent} from '../add-member/add-member.component'
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {EditMemberComponent} from '../edit-member/edit-member.component'
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component'
import * as _ from "lodash";
import {SnackbarService} from '../../services/snackbar.service'
import {take} from 'rxjs/operators';
import {lowerCase} from 'lodash';

@Component({
  selector: 'members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss'],
})

export class MembersListComponent implements OnInit, OnDestroy {
  displayedColumns: string[];
  public fetchingMembers$: Observable<any>;
  public fetchingMemberCount$: Observable<any>;
  public isLoading = false;
  private memberSub: Subscription;
  private memberCountSub: Subscription;
   activeMemberFilter = true;
  dataSource: any;
  paginatorControl: {
    length: number,
    pageSize: number,
    pageSizeOptions: number[]
  };

  constructor(private membersService: MembersService, private dialog: MatDialog, private snackbarService: SnackbarService ) {
    this.displayedColumns = ['memberId', 'firstName', 'lastName', 'loginEmail', 'edit', 'active'];
    this.fetchingMembers$ = this.membersService.getMembersListUpdateListener$();
    this.fetchingMemberCount$ = this.membersService.getMembersCountListener$();
    this.isLoading = false;
    this.dataSource = [];
    this.paginatorControl = {
      length: 100,
      pageSize: 10,
      pageSizeOptions: [5, 10, 20, 40, 50, 100]
    };
  }

  ngOnInit() {
    this.isLoading = true;
    this.membersService.getMemberCountData()
    this.memberCountSub = this.fetchingMemberCount$.subscribe(
      (count) => {
        this.paginatorControl.length = count;
      }
    )
    this.membersService.fetchMembersFromDatabase();
    this.isLoading = true;
    this.memberSub = this.fetchingMembers$.subscribe(
      (memberlist) => {
        if (Array.isArray(memberlist) == true) {
          this.isLoading = false;
          this.dataSource = memberlist;
        }
      }
    )
  }

  paginate(pageEvent: PageEvent) {
    const {pageSize, pageIndex} = pageEvent;
    this.membersService.updateJobsListPagination({pageSize, pageIndex});
    this.isLoading = true;
    this.membersService.fetchMembersFromDatabase(this.activeMemberFilter);
  }

  createMember() {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open(AddMemberComponent, dialogConfig);
    dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      this.isLoading = true;
      this.membersService.getMemberCountData()
      this.membersService.fetchMembersFromDatabase()
    })
  }

  onStateChange(event: any, member: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      member: member,
      action: (event.checked == true) ? "Activate" : "Archive"
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((dialogResult) => {
        if (dialogResult == true) {
          let memberData = {
            loginEmail: member.loginEmail,
            actionType: (event.checked == true) ? "Enable" : "Disable"
          }
          this.membersService.changeMemberState(memberData).subscribe(
            (responseData: any) => {
              this.snackbarService.showSnackbar(responseData.message);
              this.showMembers(this.activeMemberFilter);
            },
            (err) => {
              event.source.checked = event.checked !== true;
              console.error(err)
              this.snackbarService.handleError(`Could not ${lowerCase(memberData.actionType)} member.`)
            }
          );
        } else {
          event.source.checked = event.checked !== true;
        }
      });
  }

  editMember(member: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = member
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let dialogRef = this.dialog.open(EditMemberComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (!(_.isEmpty(dialogResult))) {
        this.dataSource.find((attr: any, i: number) => {
          if (attr.loginEmail === `${dialogResult.loginEmail}`) {
            this.dataSource[i].firstName = dialogResult.firstName;
            this.dataSource[i].lastName = dialogResult.lastName;
            this.dataSource[i].loginEmail = dialogResult.loginEmail;
          }
        });
      }
    });
  }

  showMembers(isActive: boolean){
    this.activeMemberFilter = isActive;

    this.membersService.getMemberCountData(this.activeMemberFilter)
    this.memberCountSub = this.fetchingMemberCount$.subscribe(
      (count) => {
        this.paginatorControl.length = count;
      }
    )

    this.membersService.fetchMembersFromDatabase(this.activeMemberFilter);
    this.isLoading = true;
    this.memberSub = this.fetchingMembers$.subscribe(
      (memberlist) => {
        if (Array.isArray(memberlist) == true) {

          this.isLoading = false;
          this.dataSource = memberlist;
        }
      }
    )

  }

  ngOnDestroy() {
    this.memberSub.unsubscribe();
    this.memberCountSub.unsubscribe();
  }
}


