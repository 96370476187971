import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {UploadService} from '../../services/upload.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'prism-upload-dropzone',
  templateUrl: './upload-dropzone.component.html',
  styleUrls: ['./upload-dropzone.component.scss'],
})
export class UploadDropzoneComponent implements AfterViewInit {
  agreementType: string;
  isHovering!: boolean;
  @ViewChild('platformUploadButton', {static: true, read: ElementRef}) platformUploadButton!: ElementRef;
  @ViewChild('mediaUploadButton', {static: true, read: ElementRef}) mediaUploadButton!: ElementRef;
  fileUploaded: BehaviorSubject<boolean>;
  file!: File;

  constructor(private uploadService: UploadService) {
    this.fileUploaded = new BehaviorSubject<boolean>(false)
    this.agreementType = 'platform'
  }

  toggleHover(event: any) {
    this.isHovering = event;
  }

  onDrop(file: any) {
    this.file = file;
    this.fileUploaded.next(true);
  }

  onClick() {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf'
    input.click();
    input.onchange = (e: any) => {
      this.file = e.target!.files[0];
      this.fileUploaded.next(true)
    }
  }

  startUpload() {
    this.uploadService.uploadToMember(this.file, this.agreementType).then((res: any) => {
      this.fileUploaded.next(res)
    });
  }

  cancelUpload() {
    this.fileUploaded.next(false)
  }

  ngAfterViewInit() {

  }

  agreementToggle(type) {
    if (this.agreementType !== type) {
      this.agreementType = type
    }
  }
}
