import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, UntypedFormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import {BehaviorSubject} from 'rxjs';
import {MembersService} from 'src/app/services/members.service';
import {saveAs} from 'file-saver';
import {SnackbarService} from '../../services/snackbar.service';
import * as _ from "lodash";

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'prism-member-downloads',
  templateUrl: './total-invoices.component.html',
  styleUrls: ['./total-invoices.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS
    },
  ]
})
export class TotalInvoiceComponent implements OnInit {
  start: any = {};
  end: any = {};
  dataSource: any;
  isLoading: boolean;
  dateInformationForm!: FormGroup;
  startDateChanged: BehaviorSubject<boolean>;
  endDateChanged: BehaviorSubject<boolean>;

  invoiceCount:number;
  invoiceTotal:number;
  tableData: any[] = [];

  displayedColumns: string[] = ['period', 'count', 'amount'];

  constructor(private formBuilder: FormBuilder, private memberService: MembersService, private snackbarService: SnackbarService) {
    this.start = {};
    this.end = {};
    this.isLoading = false;
    this.dataSource = [];
    this.startDateChanged = new BehaviorSubject<boolean>(true);
    this.endDateChanged = new BehaviorSubject<boolean>(true);
    this.tableData = [
      { period: ``,
        count: this.invoiceCount,
        total: this.invoiceTotal },
    ];
  }

  ngOnInit() {
    const defaultStartDate = moment().subtract(1, 'months');
    const defaultEndDate = moment();

    this.dateInformationForm = this.formBuilder.group({
      startDate: new UntypedFormControl(defaultStartDate, [Validators.required]),
      endDate: new UntypedFormControl(moment(), [Validators.required])
    });
    this.dataSource = this.tableData;

    this.start = {
      date: defaultStartDate.date(),
      milliseconds: 0,
      seconds: 0,
      minutes: 0,
      hours: 0,
      months: defaultStartDate.month(),
      years: defaultStartDate.year()
    };

    this.end = {
      date: defaultEndDate.date(),
      milliseconds: defaultEndDate.milliseconds(),
      seconds: defaultEndDate.seconds(),
      minutes: defaultEndDate.minutes(),
      hours: defaultEndDate.hours(),
      months: defaultEndDate.month(),
      years: defaultEndDate.year()
    };
  }

  leadingZero(value: number) {
    return (value < 10) ? ("0" + value) : value;
  }

  onStartDateChange(newValue: string) {
    if (newValue?.['_d']) {
      const dateString = newValue['_i'];
      const [monthStr, yearStr] = dateString.split("/");

      const month = parseInt(monthStr, 10) -1;
      const year = parseInt(yearStr, 10);

      // Check if the date is valid. Year limit included as the date picker misbehaves with years less than 1000
      if (isNaN(month) || isNaN(year) || year < 1000 || month < 0 || month > 12) {
        return;
      }

      const ctrlValue = this.startDateControl?.value;
      ctrlValue.month(month);
      ctrlValue.year(year);

      this.startDateControl?.setValue(ctrlValue);
      this.start = this.startDateControl?.value.toObject();
    }
  }

  onEndDateChange(newValue: string) {
    if (newValue?.['_d']) {
      const dateString = newValue['_i'];
      const [monthStr, yearStr] = dateString.split("/");

      const month = parseInt(monthStr, 10) -1;
      const year = parseInt(yearStr, 10);

      // Check if the date is valid. Year limit included as the date picker misbehaves with years less than 1000
      if (isNaN(month) || isNaN(year) || year < 1000 || month < 0 || month > 12) {
        return;
      }

      const ctrlValue = this.endDateControl?.value;
      ctrlValue.month(month);
      ctrlValue.year(year);

      this.endDateControl?.setValue(ctrlValue);
      this.end = this.endDateControl?.value.toObject();
      this.endDateChanged.next(false)
    }
  }

  getInvoiceData() {
    if (_.isEmpty(this.start) || _.isEmpty(this.end)) {
      this.snackbarService.handleError("Please select a valid date range.")
    } else if (this.start.years < this.end.years || (this.start.years === this.end.years && this.start.months <= this.end.months)) {
      this.memberService.getTotalInvoiceData(this.start, this.end).subscribe((responseData: any) => {
        if (responseData.code !== 200) {
          this.snackbarService.showSnackbar(responseData.message);
        } else {
          this.snackbarService.showSnackbar(responseData.message);

          this.invoiceTotal = responseData.data.totalAmount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });

          this.invoiceCount = responseData.data.invoiceCount.toLocaleString();

          this.tableData = [
            { period: `${this.leadingZero(this.start.months + 1)}/${this.start.years} - ${this.leadingZero(this.end.months + 1)}/${this.end.years}`,
              count: this.invoiceCount,
              total: this.invoiceTotal },
          ];
        }
      })
    } else {
      this.snackbarService.handleError("Please select a valid date range. Ensure the start date precedes the end date.")
    }
  }

  chosenStartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.startDateControl?.value;
    ctrlValue.month(normalizedMonth.month());
    this.startDateControl?.setValue(ctrlValue);
    this.start = this.startDateControl?.value.toObject();
    this.startDateChanged.next(false)
    datepicker.close();
  }

  chosenStartYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.startDateControl?.value;
    ctrlValue.year(normalizedYear.year());
    this.startDateControl?.setValue(ctrlValue);
  }

  chosenEndMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.endDateControl?.value;
    ctrlValue.month(normalizedMonth.month());
    this.endDateControl?.setValue(ctrlValue);
    this.end = this.endDateControl?.value.toObject();
    this.endDateChanged.next(false)
    datepicker.close();
  }

  chosenEndYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.endDateControl?.value
    ctrlValue.year(normalizedYear.year());
    this.endDateControl?.setValue(ctrlValue);
  }

  get startDateControl() {
    return this.dateInformationForm.get('startDate');
  }

  get endDateControl() {
    return this.dateInformationForm.get('endDate');
  }

}
