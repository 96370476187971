<div class="mat-dialog-container">
  <h1 mat-dialog-title>Create new member</h1>

  <mat-dialog-content>
    <form class="add-member-form" [formGroup]="emailFormGroup" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <div class="form-field form-field-input">
          <mat-label class="form-field-label">Email:</mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="text" formControlName="email" required autocomplete="off">

            <mat-error *ngIf="emailFormGroup.get('email')?.invalid">
              Please enter a valid email.
            </mat-error>

          </mat-form-field>

        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button [mat-dialog-close]="true"
            class="btn btn-red cancel-btn"
            mat-button
    >
      Cancel
    </button>
    <button
      mat-raised-button
      type="submit"
      class="btn btn-green"
      (click)="onSubmit()"
    >
      Create Member
    </button>
  </mat-dialog-actions>
</div>
