import {RouterModule} from '@angular/router';
import {LoginComponent} from '../../components/login/login.component';

const loginRoutes = [
  {
    path: '',
    component: LoginComponent,
  }
];

export const LoginRouting = RouterModule.forChild(loginRoutes);
