import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// @ts-ignore
import * as moment from "moment";
import { BehaviorSubject, Observable, of, ReplaySubject, timer } from 'rxjs';
import { finalize, shareReplay, take, tap } from 'rxjs/operators';
import {environment} from "../../environments";
import {PrismHeaders} from "../enums";

@Injectable()
export class XeroConnectionService {
  accessExpiresAt: moment.Moment;
  private xeroLoading: BehaviorSubject<boolean>;
  private organisation: BehaviorSubject<any>;
  public organisation$: Observable<any>;
  private connectionData: ReplaySubject<any>;
  private sessionExpiredBS: BehaviorSubject<boolean>;
  public sessionExpired$: Observable<boolean>;
  private _xeroExpiryTimestamp: BehaviorSubject<number>;
  private xeroExpiryTimer$: BehaviorSubject<Observable<any>>;

  constructor(private http: HttpClient) {
    this.xeroLoading = new BehaviorSubject<boolean>(true);
    this.organisation = new BehaviorSubject<any>(null);
    this.organisation$ = this.organisation.asObservable();
    this.connectionData = new ReplaySubject<any>(1);
    this.sessionExpiredBS = new BehaviorSubject<boolean>(null);
    this.sessionExpired$ = this.sessionExpiredBS.asObservable();
    this._xeroExpiryTimestamp = new BehaviorSubject<number>(null);
    this.xeroExpiryTimer$ = new BehaviorSubject<Observable<any>>(timer(0));
  }

  getToken() {
    this.xeroLoading.next(true);
    this.sessionExpiredBS.next(null);
    return this.http.get(environment.apiBaseUrl + '/GetXeroRequestToken')
      .pipe(
        shareReplay(1),
        tap((tokenResponse: any) => {
          location.assign(tokenResponse.data);
        })
      );
  }

  getXeroExpiryTimer() {
    return this.xeroExpiryTimer$.asObservable();
  }

  saveToken(at: string) {
    const headers = new HttpHeaders()
      .append(PrismHeaders.QueryData, JSON.stringify({at}));

    this.http.get(`${environment.apiBaseUrl}/SaveToken`, {headers})
      .subscribe((response: Response) => {
        this.getXeroConnection()
          .pipe(take(1))
          .subscribe(
            () => {

            },
            (err) => console.error(err));
      });
  }

  get sessionExpired() {
    return this.sessionExpiredBS.getValue();
  }

  getXeroConnection() {
    return this.http.get(environment.apiBaseUrl + '/GetAdminConnection')
      .pipe(
        shareReplay(1),
        tap(
          (connectionResponse: any) => {
            const connection = connectionResponse.data;
            const {exp: expiryTimestamp, tenants} = connection;
            this._xeroExpiryTimestamp.next(expiryTimestamp * 1000);
            if (connection === null) {
              this.sessionExpiredBS.next(true);
            } else {
              this.connectionData.next(connection);
              this.organisation.next(tenants[0].tenantName);
              const expiryValueFromDB = expiryTimestamp;

              this.accessExpiresAt = expiryValueFromDB
                ? moment(expiryValueFromDB * 1000)
                : null;

              const expiryTime = this.accessExpiresAt;
              const currentTime = moment().add(1, 'minute');
              if (moment(expiryTime).diff(currentTime) > 0) {
                this.xeroExpiryTimer$.next(timer(moment(expiryTime).diff(currentTime)));

              } else {
                this.xeroExpiryTimer$.next(of(0));
              }
              const sessionExpiredResult = currentTime.isAfter(expiryTime);
              this.sessionExpiredBS.next(sessionExpiredResult);
            }

          },
          (connectionErrorResponse: any) => {
            this.sessionExpiredBS.next(true);
            console.error('CONNECTION ERROR:', connectionErrorResponse.message);
          }
        ),
        finalize(() => {
          this.xeroLoading.next(false);
        })
      );
  }

  get xeroLoading$() {
    return this.xeroLoading.asObservable();
  }
}
