import {BehaviorSubject, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  User,
} from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private sessionTokenSource: BehaviorSubject<string> = new BehaviorSubject<string>("null");
  private _loggedIn: BehaviorSubject<boolean>;
  private _activatedRoute: string;
  public showLoginLoader: Subject<boolean>;

  constructor(
    private router: Router
  ) {
    this._loggedIn = new BehaviorSubject<boolean>(null);
    this.showLoginLoader = new Subject<boolean>();
    this.sessionTokenSource = new BehaviorSubject<string>(null);
    this._activatedRoute = '';
    this.initAuthListener()
  }

  get sessionTokenRaw() {
    return this.sessionTokenSource.value;
  }

  get sessionToken$() {
    return this.sessionTokenSource
      .asObservable();
  }

  login(email: string, password: string): Promise<boolean | any> {
    const auth = getAuth()
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then(user => {
          resolve(user);
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  initAuthListener() {
    try {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user: User) => {
        this._activatedRoute = window.location.pathname;

        if (!!user && !user.isAnonymous) {
          await this.initAdminAuth(user);
          this.setLoggedInState(!!user);
        }

        if (!!user && !!user.email) {
          this.setLoggedInState(true);
          if (this._activatedRoute === '/login') {
            this.router.navigate(['/home/members']);
          } else {
            this.router.navigate([this._activatedRoute]);
          }
        } else {
          this.setLoggedInState(false);
          this.router.navigateByUrl('/login');
        }
      });
    } catch (e) {
      console.error(e)
    }
  }

  async initAdminAuth(user: User) {
    if (!!user && !user.isAnonymous) {
      if (user?.uid) {
        const sessionToken = await user.getIdToken();
        this.sessionTokenSource.next(sessionToken);
      }
    }
  }

  setLoggedInState(value: boolean) {
    this._loggedIn.next(value);
  }

  isLoggedIn$() {
    return this._loggedIn.asObservable();
  }

  logout() {
    this._loggedIn.next(false)
    return getAuth().signOut();
  }

  isLoggedIn() {
    return this._loggedIn.getValue();
  }
}
