import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';
import {BaseComponent} from '../../components/base/base.component';
import {LoggedInGuard} from "../../services/guards/logged-in.gaurd";

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home/members'
  },
  {
    path: 'login',
    loadChildren: () => import('../modules/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: 'home',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('../modules/home-core.module').then(m => m.HomeCoreModule),
      }
    ]
  }, {
    path: '**',
    redirectTo: '/home/members'
  },
]

export const AppRouting: ModuleWithProviders<any> = RouterModule
  .forRoot(appRoutes, {
    preloadingStrategy: PreloadAllModules,
  });
