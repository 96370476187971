import {NgModule} from '@angular/core';
import {AppComponent} from '../../components/app/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {UiModule} from './ui.module';
import {AppRouting} from '../routing/app.routing';
import {FirebaseConfig} from '../../../environments';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';

import {initializeApp} from "firebase/app";
import {XeroConnectionService} from "../../services/xero-connection.service";
import {AuthInterceptor} from "../../services/interceptors";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    RouterModule,
    BrowserAnimationsModule,
    UiModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    AppRouting
  ],
  providers: [XeroConnectionService, {
    useClass: AuthInterceptor,
    provide: HTTP_INTERCEPTORS,
    multi: true,
  },],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    initializeApp(FirebaseConfig.AdminAppConfig);
  }
}
