import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {WhitelistService} from '../../services/whitelist.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-domain-dialog',
  templateUrl: './edit-domain-dialog.component.html',
  styleUrls: ['./edit-domain-dialog.component.scss']
})
export class EditDomainDialogComponent implements OnInit {
  editDomainForm: FormGroup;
  isLoading = false;
  fileName: string;
  domainUrl = this.data.domain.domain;

  constructor(
    private fb: FormBuilder,
    private whitelistService: WhitelistService,
    private dialogRef: MatDialogRef<EditDomainDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.editDomainForm = this.fb.group({
      url: [data.domain.url, [Validators.required, Validators.pattern(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)]],
      thumbnail: ['']
    });
  }

  ngOnInit(): void {
  }

  async onSubmit() {
    if (this.editDomainForm.valid) {
      this.isLoading = true;
      const thumbnailFile = this.editDomainForm.value.thumbnail;

      try {
        const thumbnailBase64 = thumbnailFile ? await this.whitelistService.convertFileToBase64(thumbnailFile) : null;
        const updatedDomain = {
          domain: this.data.domain.domain,
          url: this.editDomainForm.value.url,
          thumbnail: thumbnailBase64
        };

        this.whitelistService.updateDomain(updatedDomain).subscribe(
          () => {
            this.isLoading = false;
            this.dialogRef.close(updatedDomain);
            this.snackBar.open('Domain successfully updated', 'Close', {
              duration: 5000,
              verticalPosition: 'top',
            });
          },
          (error) => {
            this.isLoading = false;
            console.error('Update error:', error);
            this.snackBar.open('Error updating domain. Please try again later.', 'Close', {
              duration: 5000,
              verticalPosition: 'top',
            });
          }
        );
      } catch (error) {
        this.isLoading = false;
        console.error('Thumbnail conversion error:', error);
        this.snackBar.open('Failed to process the thumbnail image.', 'Close', {
          duration: 5000,
          verticalPosition: 'top',
        });
      }
    } else {
      this.snackBar.open('Invalid domain URL', 'Close', {
        duration: 5000,
        verticalPosition: 'top',
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onDelete() {
    this.isLoading = true;
    this.whitelistService.deleteDomain(this.data.domain.domain)
      .subscribe(() => {
          this.isLoading = false;
          this.dialogRef.close({domain: this.data.domain.domain});
          this.snackBar.open('Domain successfully deleted', 'Close', {
            duration: 5000,
            verticalPosition: 'top',
          });
        }, (error) => {
          this.isLoading = false;
          console.error('Delete error:', error);
          this.snackBar.open('Error deleting domain. Please try again later.', 'Close', {
            duration: 5000,
            verticalPosition: 'top',
          });
        }
      );
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.fileName = file.name;
      this.editDomainForm.patchValue({thumbnail: file});
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      this.fileName = file.name;
      this.editDomainForm.patchValue({thumbnail: file});
      event.dataTransfer.clearData();
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.addDragOverClass();
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.addDragOverClass();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.removeDragOverClass();
  }


  private addDragOverClass() {
    const dropzone = document.querySelector('.form-field-input.dropzone');
    if (dropzone) {
      dropzone.classList.add('drag-over');
    }
  }

  private removeDragOverClass() {
    const dropzone = document.querySelector('.form-field-input.dropzone');
    if (dropzone) {
      dropzone.classList.remove('drag-over');
    }
  }
}
