import { NgModule } from "@angular/core";
import {CommonModule} from "@angular/common";
import {HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {UiModule} from "./ui.module";
import {BaseComponent} from "../../components/base/base.component";
import {HeaderComponent} from "../../components/header/header.component";
import {SidebarComponent} from "../../components/sidebar/sidebar.component";
import {XeroDisplayComponent} from "../../components/header/xero-display/xero-display.component";
import {UploadDropzoneComponent} from "../../components/upload-dropzone/upload-dropzone.component";
import {ConfirmDialogComponent} from "../../components/confirm-dialog/confirm-dialog.component";


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    HttpClientJsonpModule,
    UiModule.full()
  ],
  declarations: [
    BaseComponent,
    HeaderComponent,
    SidebarComponent,
    XeroDisplayComponent,
    UploadDropzoneComponent,
    ConfirmDialogComponent
  ],
  exports: [
    UploadDropzoneComponent,
    UiModule,
    CommonModule,
    RouterModule,
    HttpClientModule
  ]
})

export class SharedModule {
}
