<div class="login-container">
  <mat-card appearance="outlined" class="form-container" [formGroup]="loginForm">
    <div class="logo">
      <img src="../../../assets/images/bwrm-new-logo.png" alt="BWRM">
    </div>

    <div class="form-field form-field-input">
      <mat-label class="form-field-label">Email</mat-label>

      <mat-form-field appearance="outline">
        <input matInput placeholder="Enter your email address" type="email" formControlName="email"
               (keyup.enter)="onLogin(loginForm.valid)">
      </mat-form-field>
    </div>

    <div class="form-field form-field-input">
      <mat-label class="form-field-label">Password</mat-label>

      <mat-form-field appearance="outline">
        <input matInput
               placeholder="Enter your password"
               type="password"
               formControlName="password"
               (keyup.enter)="onLogin(loginForm.valid)">
        <mat-hint *ngIf="loginForm.controls['password'].dirty && loginForm.controls['password'].invalid">Password must
          contain at least {{minPassChars}} characters
        </mat-hint>
      </mat-form-field>
    </div>

    <button class="btn btn-green login-btn" mat-raised-button (click)="onLogin(loginForm.valid)">Log In</button>

    <button class="btn forgot-password-btn btn-link" mat-button [routerLink]="['/setup', 'passwordreset']">Forgot
      Password
    </button>

  </mat-card>
</div>
