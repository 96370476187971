import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {environment} from "../../environments";
import {PrismHeaders} from "../enums";

@Injectable({
  providedIn: 'root'
})
export class XeroProductsService {
  private fetchingXeroProducts: BehaviorSubject<boolean>
  private fetchingXeroProductsCount: BehaviorSubject<boolean>
  private _xeroProductsListPaginationData: BehaviorSubject<any>
  private _xeroProducts$: BehaviorSubject<XeroItem[]>;
  private _xeroProductsCount$: BehaviorSubject<number>;

  constructor(private http: HttpClient) {
    this.fetchingXeroProducts = new BehaviorSubject<boolean>(false);
    this.fetchingXeroProductsCount = new BehaviorSubject<boolean>(false);
    this._xeroProducts$ = new BehaviorSubject<XeroItem[]>([]);
    this._xeroProductsCount$ = new BehaviorSubject<number>(0);
    this._xeroProductsListPaginationData = new BehaviorSubject<any>({
      pageSize: 10,
      pageIndex: 0
    });
  }

  getXeroProducts() {
    this.fetchingXeroProducts.next(true)
    const paginationData = this._xeroProductsListPaginationData.value;
    const headers = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify({...paginationData}));
    this.http.get(`${environment.apiBaseUrl}/GetXeroProducts`, {headers})
      .pipe(
        take(1),
        map((xeroProductsResponse: any) => {
          return xeroProductsResponse.data as XeroItem[]
        })
      )
      .subscribe((xeroProducts) => {
        this.setXeroProducts(xeroProducts)
        this.fetchingXeroProducts.next(false)
      })
  }

  updateXeroProductsListPagination({pageSize, pageIndex}: any) {
    this._xeroProductsListPaginationData.next({pageSize, pageIndex});
  }

  getXeroProductsCount() {
    this.fetchingXeroProductsCount.next(true)
    this.http.get(`${environment.apiBaseUrl}/GetXeroProductsCount`)
      .pipe(
        take(1),
        map((xeroProductsResponse: any) => {
          return xeroProductsResponse.data.counter as number;
        })
      )
      .subscribe((count) => {
        this.setXeroProductsCount(count)
        this.fetchingXeroProductsCount.next(false)
      })
  }

  get xeroProducts$() {
    return this._xeroProducts$.asObservable()
  }

  get xeroProductsCount$() {
    return this._xeroProductsCount$.asObservable()
  }

  setXeroProducts(xeroProducts: XeroItem[]) {
    this._xeroProducts$.next(xeroProducts)
  }


  setXeroProductsCount(xeroProducts: number) {
    this._xeroProductsCount$.next(xeroProducts)
  }

  updateXeroProductActiveStatus(id: string, active: boolean) {
    return this.http.put(`${environment.apiBaseUrl}/PutXeroProductsActiveStatus`, {id, active})
      .pipe(
        take(1)
      )
  }

  putXeroProductPriority(xeroProduct: XeroItem) {
    return this.http.put(`${environment.apiBaseUrl}/PutXeroProductPriority`, {id: xeroProduct.id, priority: xeroProduct.priority})
      .pipe(
        take(1)
      )
  }

  updateXeroProductServiceType(id: string, serviceType: ServiceTypes) {
    return this.http.put(`${environment.apiBaseUrl}/PutXeroProductsServiceType`, {id, serviceType})
      .pipe(
        take(1)
      )
  }
}

export interface XeroItem {
  id: string;
  itemCode: string;
  itemName: string;
  createdAt: string;
  purchasesDescription: string;
  purchasesUnitPrice: string;
  purchasesAccount: string;
  purchasesTaxRate: string;
  salesDescription: string;
  salesUnitPrice: string;
  salesAccount: string;
  salesTaxRate: string;
  inventoryAssetAccount: string;
  costOfGoodsSoldAccount: string;
  active?: boolean;
  serviceType?: ServiceTypes,
  disabled: boolean;
  priority?: 'major' | 'minor'
}

export enum ServiceTypesEnum {
  IMAGE_SERVICE = 'imageService',
  VIDEO_SERVICE = 'videoService',
  FLOORPLAN_SERVICE = 'floorplanService',
}

export type ServiceTypes =
  ServiceTypesEnum.IMAGE_SERVICE
  | ServiceTypesEnum.VIDEO_SERVICE
  | ServiceTypesEnum.FLOORPLAN_SERVICE;
