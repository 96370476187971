import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../services/authentication.service'

@Component({
  selector: 'prism-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [FormBuilder]
})

export class LoginComponent implements OnInit {
  minPassChars: number;
  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(this.minPassChars)]],
    });
    this.minPassChars = 6;
  }

  ngOnInit() {
  }

  public onLogin(validState: any) {
    if (validState) {
      const email = this.loginForm.controls['email'].value;
      const password = this.loginForm.controls['password'].value;

      if (this.loginForm.valid) {
        this.loginForm.disable();
        this.authService.login(email, password)
          .catch(() => {
            this.loginForm.enable();
          });
      }
    }
  }
}
