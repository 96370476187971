import {BehaviorSubject} from 'rxjs';
import {take} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/';
import {PrismHeaders} from '../enums';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ProcessorsService {
  constructor(private http: HttpClient) {
  }

  private fetchingProcessors: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private fetchingProcessorCount: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private _processorsListPaginationData: BehaviorSubject<any> = new BehaviorSubject<any>({
    pageSize: 10,
    pageIndex: 0
  });

  getProcessors() {
    const paginationData = this._processorsListPaginationData.value;
    const headers = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify({...paginationData}));

    this.http.get(environment.apiBaseUrl + '/GetProcessors', {headers})
      .pipe(
        take(1),
        map((response: any) => {
          return response.data.map((data: any) => {
            return {
              id: data.id,
              firstName: data.firstName = (data.firstName === undefined ? '-' : data.firstName),
              lastName: data.lastName = (data.lastName === undefined ? '-' : data.lastName),
              loginEmail: data.loginEmail,
              disabled: data.disabled
            }
          })
        })
      )
      .subscribe((data) => {
        this.fetchingProcessors.next(data);
      })
  }

  getProcessorCountData() {
    this.http.get(environment.apiBaseUrl + '/GetProcessorCount')
      .pipe(
        take(1),
        map((countResponse: any) => {
          return countResponse.data
        })
      ).subscribe((data) => {
      this.fetchingProcessorCount.next(data)
    })
  }

  createProcessor(processorData: any) {
    return this.http.post(environment.apiBaseUrl + '/CreateProcessor', {profile: processorData})
      .pipe(
        take(1)
      )
  }

  getProcessor(data: any) {
    let email = data.loginEmail;
    let headers = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify({email}));
    return this.http.get(environment.apiBaseUrl + '/GetProcessor', {headers})
  }

  updateProcessorPermissions(body: any) {
    return this.http.put(environment.apiBaseUrl + '/PutProcessorPermissions', body)
  }

  getProcessorsListUpdateListener$() {
    return this.fetchingProcessors.asObservable();
  }

  getProcessorsCountListener$() {
    return this.fetchingProcessorCount.asObservable();
  }

  updateJobsListPagination({pageSize, pageIndex}: any) {
    this._processorsListPaginationData.next({pageSize, pageIndex});
  }
}

