<div class="container">
  <form class="date-info-form" [formGroup]="dateInformationForm">
    <div class="field-group">
      <mat-form-field appearance="fill">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="dp1" formControlName="startDate" required (dateInput)="onStartDateChange($event.target.value)">
        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
        <mat-datepicker #dp1
                        startView="multi-year"
                        (monthSelected)="chosenStartMonthHandler($event, dp1)"
                        (yearSelected)="chosenStartYearHandler($event)"
                        panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>
    </div>
    <div class="field-group">
      <mat-form-field appearance="fill">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="dp2" formControlName="endDate" required (dateInput)="onEndDateChange($event.target.value)">
        <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
        <mat-datepicker #dp2
                        startView="multi-year"
                        (monthSelected)="chosenEndMonthHandler($event, dp2)"
                        (yearSelected)="chosenEndYearHandler($event)"
                        panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>
    </div>
  </form>
  <div class="account-actions">

    <table mat-table [dataSource]="tableData" class="mat-elevation-z8">

      <!-- ID Column -->
      <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef>Invoice Period</th>
        <td mat-cell *matCellDef="let element">{{ element.period }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef>Number of Invoices</th>
        <td mat-cell *matCellDef="let element">{{ element.count }}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Total Amount</th>
        <td mat-cell *matCellDef="let element">{{ element.total ? '$' + element.total : ''}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-progress-bar
      *ngIf="isLoading"
      class="progress-bar"
      color="primary"
      mode="query"
    >
    </mat-progress-bar>
  </div>
  <div class="account-actions">
    <button mat-raised-button
            class="btn btn-blue all-btn"
            (click)="getInvoiceData()"
    >
      Fetch Queried Data
    </button>
  </div>
</div>
