import {NgModule} from '@angular/core';
import {LoginRouting} from '../routing/login.routing';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {LoginComponent} from "../../components/login/login.component";
import {RouterModule} from "@angular/router";
import {UiModule} from "./ui.module";

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    UiModule.full(),
    RouterModule,
    LoginRouting
  ]
})

export class LoginModule {
  constructor() {

  }
}
