<div class="container xero-products-table">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Sub-service</th>
      <td mat-cell *matCellDef="let xeroProduct"> {{ xeroProduct.itemName }}</td>
    </ng-container>

    <ng-container matColumnDef="purchasesUnitPrice">
      <th mat-header-cell *matHeaderCellDef>Cost Price</th>
      <td mat-cell *matCellDef="let xeroProduct"> {{ xeroProduct.purchasesUnitPrice || 'None' }}</td>
    </ng-container>

    <ng-container matColumnDef="salesUnitPrice">
      <th mat-header-cell *matHeaderCellDef>Sales Price</th>
      <td mat-cell *matCellDef="let xeroProduct"> {{ xeroProduct.salesUnitPrice || 'None' }}</td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef>Active</th>
      <td mat-cell *matCellDef="let xeroProduct">
        <mat-slide-toggle [disabled]="xeroProduct.disabled" [checked]="xeroProduct.active"
                          (click)="updateXeroProductActiveStatus(xeroProduct.active, xeroProduct)">
        </mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container class="btn-column" matColumnDef="major">
      <th class="btn-column-header" mat-header-cell *matHeaderCellDef>Major</th>
      <td class="btn-column-data" mat-cell *matCellDef="let xeroProduct">
        <mat-checkbox class="example-margin"
                      [checked]="xeroProduct.priority === 'major'"
                      (change)="updateXeroProductPriority($event, xeroProduct)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container class="btn-column" matColumnDef="image">
      <th class="btn-column-header" mat-header-cell *matHeaderCellDef>Image</th>
      <td class="btn-column-data" mat-cell *matCellDef="let xeroProduct">
        <mat-checkbox class="example-margin"
                      [disabled]="productServiceTypeSelected[xeroProduct.id] === ServiceTypesEnum.IMAGE_SERVICE"
                      [checked]="productServiceTypeSelected[xeroProduct.id] === ServiceTypesEnum.IMAGE_SERVICE"
                      (change)="updateXeroProductServiceType(xeroProduct, ServiceTypesEnum.IMAGE_SERVICE)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container class="btn-column" matColumnDef="floorplan">
      <th class="btn-column-header" mat-header-cell *matHeaderCellDef>Floorplan</th>
      <td class="btn-column-data" mat-cell *matCellDef="let xeroProduct">
        <mat-checkbox class="example-margin"
                      [disabled]="productServiceTypeSelected[xeroProduct.id] === ServiceTypesEnum.FLOORPLAN_SERVICE"
                      [checked]="productServiceTypeSelected[xeroProduct.id] === ServiceTypesEnum.FLOORPLAN_SERVICE"
                      (change)="updateXeroProductServiceType(xeroProduct, ServiceTypesEnum.FLOORPLAN_SERVICE)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container class="btn-column" matColumnDef="video">
      <th class="btn-column-header" mat-header-cell *matHeaderCellDef>Video</th>
      <td class="btn-column-data" mat-cell *matCellDef="let xeroProduct">
        <mat-checkbox class="example-margin"
                      [disabled]="productServiceTypeSelected[xeroProduct.id] === ServiceTypesEnum.VIDEO_SERVICE"
                      [checked]="productServiceTypeSelected[xeroProduct.id] === ServiceTypesEnum.VIDEO_SERVICE"
                      (change)="updateXeroProductServiceType(xeroProduct, ServiceTypesEnum.VIDEO_SERVICE)">
        </mat-checkbox>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-progress-bar
    *ngIf="isLoading"
    class="progress-bar"
    color="primary"
    mode="query"
  >
  </mat-progress-bar>
  <mat-paginator
    [length]="paginatorControl.length"
    [pageSize]="paginatorControl.pageSize"
    [pageSizeOptions]="paginatorControl.pageSizeOptions"
    (page)="paginate($event)"
  >
  </mat-paginator>
</div>
