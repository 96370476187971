<div class="mat-dialog-container" *ngIf="!isLoading">
  <h1 mat-dialog-title>Permissions</h1>

  <mat-dialog-content>
    <form class="personal-info-form" [formGroup]="processorProfileFormGroup">
      <div class="form-group">
        <h3 class="form-group-title">Service Permissions</h3>
        <div class="form-field form-field-checkbox">
          <span class="sub-section-title">Image Services</span>
          <mat-checkbox color="primary"
                        [formControl]="imageServicesFormControl"
          >
          </mat-checkbox>
        </div>
        <div class="form-field form-field-checkbox">
          <span class="sub-section-title">Floorplan Services</span>
          <mat-checkbox color="primary"
                        [formControl]="floorplanServicesFormControl"
          >
          </mat-checkbox>
        </div>
        <div class="form-field form-field-checkbox">
          <span class="sub-section-title">Video Services</span>
          <mat-checkbox color="primary"
                        [formControl]="videoServicesFormControl"
          >
          </mat-checkbox>
        </div>
        <h3 class="form-group-title">Profile Permissions</h3>
        <div class="form-field form-field-checkbox">
          <span class="sub-section-title">Allow Invoicing</span>
          <mat-checkbox color="primary"
                        [formControl]="invoicingPermissionsFormControl"
          >
          </mat-checkbox>
        </div>
        <div class="form-field form-field-checkbox">
          <span class="sub-section-title">View Cost Pricing</span>
          <mat-checkbox color="primary"
                        [formControl]="costViewingPermissionsFormControl"
          >
          </mat-checkbox>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button
            class="btn btn-red cancel-btn"
            (click)="closeDialog()">
      Cancel
    </button>
    <button mat-raised-button
            class="btn btn-green"
            (click)="saveProcessor()"
            [disabled]="!(formChanged | async) || processorProfileFormGroup.invalid || updatingProcessor">
      Save
    </button>
  </mat-dialog-actions>
</div>
<mat-spinner *ngIf="isLoading"></mat-spinner>
