<div class="xero-info">
  <div
    class="xero-badge"
    *ngIf="(xeroExpiryTimer$|async) > -1 && !((xeroLoading$ | async)); else loader"
    [ngClass]="{'xero-disconnected': (xeroExpiryTimer$ | async) === 0,'xero-connected': (xeroExpiryTimer$ | async) !== 0}"
  ></div>
  <ng-template #loader>
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </ng-template>
  <div class="xero-logo">
    <img
      class="header-right-btn xero-toggle-button"
      src="../../../assets/icons/xero-logo.svg"
      alt="Xero"
      (click)="toggleXeroDisplay()"
    />
  </div>
  <div class="xero-info-container">
    <div
      class="xero-info-content"
      [ngClass]="{ active: displayXeroControl }"
      *ngIf="(xeroExpiryTimer$ | async) !== 0; else xeroNotConnected"
    >
      <div class="content-wrapper">
        <h5 class="xero-display-item organisation-info">Connected as</h5>
        <h3 class="xero-display-item organisation-info">
          {{ (organisation$ | async) }}
        </h3>
      </div>
      <div *ngIf="invoicingInProgress" class="uploader-display-progress">
        <h5 class="xero-display-item organisation-info">Progress</h5>
        <h3 class="xero-display-item organisation-info">
          Invoicing: {{invoicingProgress}}%
        </h3>
        <mat-progress-bar class="upload-progress-bar"
                          [color]="'accent'"
                          [value]="invoicingProgress">
        </mat-progress-bar>
      </div>
      <div class="btn-wrapper">
        <button
          mat-raised-button
          class="btn btn-green"
          (click)="createMemberInvoices()"
          [disabled]="(reconBusySingle$|async) || (reconBusyQueue$|async) || (xeroExpiryTimer$ | async) === 0 || invoicingInProgress"
        >
          Create Member Invoices
          <ng-template [ngIf]="(reconBusySingle$|async) || (reconBusyQueue$|async)">
            <div class="lds-hourglass"></div>
          </ng-template>
        </button>
        <button
          mat-raised-button
          class="btn btn-outline xero-display-item"
          (click)="connectToXero()"
        >
          Change Organisation
        </button>
      </div>
    </div>

    <ng-template #xeroNotConnected>
      <div
        class="xero-info-content"
        [ngClass]="{ active: displayXeroControl }"
      >
        <div class="content-wrapper">
          <h5 class="xero-display-item organisation-info">Status</h5>
          <h4>Not Connected</h4>
        </div>
        <button
          mat-icon-button
          *ngIf="queueHasError$ | async"
        >
          <mat-icon>error_outline</mat-icon>
        </button>
        <ng-template #gettingInvoiceCount>
          <mat-spinner [diameter]="20"></mat-spinner>
        </ng-template>
        <div class="btn-wrapper">
          <button
            mat-raised-button
            class="btn btn-green"
            (click)="createMemberInvoices()"
            [disabled]="true"
          >
            Create Member Invoices
            <ng-template [ngIf]="(reconBusySingle$|async) || (reconBusyQueue$|async)">
              <div class="lds-hourglass"></div>
            </ng-template>
          </button>
          <button
            mat-raised-button
            class="btn btn-xero"
            (click)="connectToXero()"
          >
            Connect to Xero
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
