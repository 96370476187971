<div class="container">
  <div class="member-download-form">
    <div class="date-form-description">
      <h2>Member Download Statistics</h2>
      <div class="account-actions">
        <button mat-raised-button
                class="btn btn-blue all-btn"
                (click)="getDownloadData()">
          Download
        </button>
      </div>
    </div>
    <form class="date-info-form" [formGroup]="downloadsForm">
      <div class="field-group">
        <mat-form-field appearance="fill">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="dp1" [formControl]="downloadsStartDateFormControl" required>
          <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
          <mat-datepicker #dp1
                          startView="multi-year"
                          (monthSelected)="chosenStartMonthHandler($event, dp1, downloadsStartDateFormControl, 'downloads')"
                          (yearSelected)="chosenStartYearHandler($event, downloadsStartDateFormControl)"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>
      <div class="field-group">
        <mat-form-field appearance="fill">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="dp2" [formControl]="downloadsEndDateFormControl" required>
          <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
          <mat-datepicker #dp2
                          startView="multi-year"
                          (monthSelected)="chosenEndMonthHandler($event, dp2, downloadsEndDateFormControl, 'downloads')"
                          (yearSelected)="chosenEndYearHandler($event, downloadsEndDateFormControl)"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="member-services-stats-form">
    <div class="date-form-description">
      <h2>Member Services Statistics</h2>
      <div class="account-actions">
        <button mat-raised-button
                class="btn btn-blue all-btn"
                (click)="getStatisticsData()">
          Download
        </button>
      </div>
    </div>
    <form class="date-info-form" [formGroup]="statisticsForm">
      <div class="field-group">
        <mat-form-field appearance="fill">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="dp3" [formControl]="statisticsStartDateFormControl" required>
          <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
          <mat-datepicker #dp3
                          (monthSelected)="chosenStartMonthHandler($event, dp3, statisticsStartDateFormControl, 'statistics')"
                          (yearSelected)="chosenStartYearHandler($event, statisticsStartDateFormControl)"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>
      <div class="field-group">
        <mat-form-field appearance="fill">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="dp4" [formControl]="statisticsEndDateFormControl" required>
          <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
          <mat-datepicker #dp4
                          (monthSelected)="chosenEndMonthHandler($event, dp4, statisticsEndDateFormControl, 'statistics')"
                          (yearSelected)="chosenEndYearHandler($event, statisticsEndDateFormControl)"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>


