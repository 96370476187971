import {Injectable} from '@angular/core';
import {FirebaseError} from 'firebase/app';
import {SnackbarService} from './snackbar.service'
import {FirebaseConfig} from '../../environments'
import {initializeApp} from "firebase/app";
import {getStorage, FirebaseStorage, ref, StorageReference, UploadTask, uploadBytesResumable} from "firebase/storage";

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private readonly memberStorage!: FirebaseStorage;

  constructor(private snackbarService: SnackbarService) {
    const memberApp = initializeApp(FirebaseConfig.MemberAppConfig, FirebaseConfig.MemberAppConfig.projectId)
    this.memberStorage = getStorage(memberApp)
  }

  async uploadToMember(file: any, action: string) {
    let newFile = new File([file], '_agreement.pdf', {type: 'application/pdf'});
    let uploadPath: string = `agreements/${action}_agreement.pdf`;
    let uploadRef: StorageReference = ref(this.memberStorage, uploadPath);
    let uploadTask: UploadTask = uploadBytesResumable(uploadRef, newFile)

    uploadTask.on('state_changed', () => {
      },
      (error: FirebaseError) => {
        console.error('Upload error code:', error.code);
        console.error('Upload error message:', error.message);
        this.snackbarService.handleError('Something went wrong with your upload');

      },
      () => {
        return new Promise<any>(() => {
          this.snackbarService.showSnackbar('Successfuly uploaded file.');
          return false;
        })
      })
  }
}
