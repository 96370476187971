export const FirebaseConfig = {
  AdminAppConfig: {
    apiKey: 'AIzaSyDGXnEqK0QgzoM0qP4XTHRnPksw-k2aGHs',
    authDomain: 'dev-bwrm-admin-prism.firebaseapp.com',
    projectId: 'dev-bwrm-admin-prism',
    storageBucket: 'dev-bwrm-admin-prism',
    messagingSenderId: '889819232518',
    appId: '1:889819232518:web:464a0f451e2279bbf18687',
    measurementId: 'G-RHVHS0K1QS'
  },
  MemberAppConfig: {
    projectId: 'dev-bwrm-prism',
    appId: '1:229659363617:web:8a4a5968c6c10292',
    databaseURL: 'https://dev-bwrm-prism.firebaseio.com',
    storageBucket: 'dev-bwrm-prism',
    apiKey: 'AIzaSyAoUzEEjxJnUEmgDdwRelXzWEvZ2Jds3L4',
    authDomain: 'dev-bwrm-prism.firebaseapp.com',
    messagingSenderId: '229659363617',
    measurementId: 'G-XM6EY3HQPC'
  }
};
