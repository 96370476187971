<div class="container">
  <div class="dialog">
    <p>Are you sure you want to {{data.action | lowercase}} {{data.member.loginEmail}}?</p>
  </div>
  <div class="account-actions">
    <button mat-raised-button
            type="button"
            color="primary"
            [mat-dialog-close]="true"
            [ngStyle]="{'color': 'white' }"
    >
      Confirm
    </button>
    <button mat-raised-button
            color="warn"
            type="button"
            [mat-dialog-close]="false"
    >
      Cancel
    </button>
  </div>
</div>
