import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SnackbarService} from './snackbar.service';
import {environment} from "../../environments";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private queueHasErrors: BehaviorSubject<boolean>;
  private _loadingQueue: BehaviorSubject<boolean>;
  private _reconBusyQueue: BehaviorSubject<boolean>;
  private _reconBusySingle: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private snackbarService: SnackbarService) {
    this.queueHasErrors = new BehaviorSubject<boolean>(false);
    this._loadingQueue = new BehaviorSubject<boolean>(false);
    this._reconBusyQueue = new BehaviorSubject<boolean>(false);
    this._reconBusySingle = new BehaviorSubject<boolean>(false);
  }

  get loadingQueue$() {
    return this._loadingQueue.asObservable();
  }

  get reconBusyQueue$() {
    return this._reconBusyQueue.asObservable();
  }

  get reconBusySingle$() {
    return this._reconBusySingle.asObservable();
  }

  get queueHasErrors$() {
    return this.queueHasErrors.asObservable();
  }

  createMemberInvoice(memberProfileUid: string) {
    return this.http.post(environment.apiBaseUrl + '/PostMemberInvoice', {memberProfileUid: memberProfileUid})
  }
}
