import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WhitelistService} from '../../services/whitelist.service';

@Component({
  selector: 'app-add-whitelist-domain',
  templateUrl: './add-whitelist-domain.component.html',
  styleUrls: ['./add-whitelist-domain.component.scss']
})
export class AddWhitelistDomainComponent {
  addDomainForm: FormGroup;
  isLoading = false;
  protected fileName: string;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddWhitelistDomainComponent>,
    private whitelistService: WhitelistService,
    private snackBar: MatSnackBar
  ) {
    this.addDomainForm = this.formBuilder.group({
      url: ['', [Validators.required, Validators.pattern(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w.-]*)*\/?(\?.*)?(#.*)?$/i)]],
      thumbnail: ['']
    });
  }

  async onSubmit() {
    if (this.addDomainForm.valid) {
      this.isLoading = true;
      const domainUrl = this.addDomainForm.value.url;
      const thumbnailFile = this.addDomainForm.value.thumbnail;

      try {
        const thumbnailBase64 = thumbnailFile ? await this.whitelistService.convertFileToBase64(thumbnailFile) : null;
        this.whitelistService.addDomain(domainUrl, thumbnailBase64).subscribe(
          () => {
            this.isLoading = false;
            this.dialogRef.close('success');
            this.snackBar.open('Domain successfully added to whitelist', 'Close', {
              duration: 5000,
              verticalPosition: 'bottom',
            });
          },
          (error) => {
            console.error('Error adding domain:', error);
            this.isLoading = false;
            this.snackBar.open('Failed to add domain. Please try again.', 'Close', {
              duration: 5000,
              verticalPosition: 'bottom',
            });
          }
        );
      } catch (error) {
        console.error('Error processing thumbnail:', error);
        this.isLoading = false;
        this.snackBar.open('Error: Failed to process the thumbnail image', 'Close', {
          duration: 5000,
          verticalPosition: 'bottom',
        });
      }
    } else {
      this.snackBar.open('Invalid domain URL', 'Close', {
        duration: 5000,
        verticalPosition: 'bottom',
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.fileName = file.name;
      this.addDomainForm.patchValue({thumbnail: file});
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      this.fileName = file.name;
      this.addDomainForm.patchValue({thumbnail: file});
      event.dataTransfer.clearData();
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.addDragOverClass();
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.addDragOverClass();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.removeDragOverClass();
  }


  private addDragOverClass() {
    const dropzone = document.querySelector('.form-field-input.dropzone');
    if (dropzone) {
      dropzone.classList.add('drag-over');
    }
  }

  private removeDragOverClass() {
    const dropzone = document.querySelector('.form-field-input.dropzone');
    if (dropzone) {
      dropzone.classList.remove('drag-over');
    }
  }

}
