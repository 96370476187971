import {BehaviorSubject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/';
import {PrismHeaders} from '../enums';

@Injectable({
  providedIn: 'root'
})

export class MembersService {
  constructor(private http: HttpClient) {
  }

  private fetchingMembers: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private fetchingMemberCount: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private _jobsListPaginationData: BehaviorSubject<any> = new BehaviorSubject<any>({
    pageSize: 10,
    pageIndex: 0
  });

  fetchMembersFromDatabase(isActive = true) {
    const paginationData = this._jobsListPaginationData.value;
    const callFunction = (isActive) ? '/GetMembers' : '/GetMembersFilter';
    const headers = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify({...paginationData}));

    this.http.get(environment.apiBaseUrl + callFunction, {headers})
      .pipe(
        take(1),
        map((jobsResponse: any) => {
          return jobsResponse.data.map((data: any) => {
            return {
              memberId: data.memberId,
              firstName: data.firstName = (data.firstName === undefined ? '-' : data.firstName),
              lastName: data.lastName = (data.lastName === undefined ? '-' : data.lastName),
              loginEmail: data.loginEmail,
              disabled: data.disabled
            }
          })
        })
      )
      .subscribe((data) => {
        this.fetchingMembers.next(data);
      })
  }

  getActiveMembers() {
    return this.http.get(environment.apiBaseUrl + '/GetMembers')
      .pipe(
        take(1),
        map((jobsResponse: any) => {
          return jobsResponse.data.map((data: any) => {
            return {
              memberId: data.memberId,
              firstName: data.firstName = (data.firstName === undefined ? '-' : data.firstName),
              lastName: data.lastName = (data.lastName === undefined ? '-' : data.lastName),
              loginEmail: data.loginEmail,
              disabled: data.disabled
            }
          })
        })
      )
  }

  getMemberCountData(isActive = true) {
    const callFunction = (isActive) ? '/GetMemberCount' : '/GetMemberCountFilter';
    this.http.get(environment.apiBaseUrl + callFunction)
      .pipe(
        take(1),
        map((countResponse: any) => {
          return countResponse.data;
        })
      ).subscribe((data) => {
      this.fetchingMemberCount.next(data);
    })
  }

  addMember(memberData: any) {
    return this.http.post(environment.apiBaseUrl + '/AddMember', memberData)
      .pipe(
        take(1)
      )
  }

  getMember(data: any) {
    let email = data.loginEmail;
    let headers = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify({email}));

    return this.http.get(environment.apiBaseUrl + '/GetMember', {headers});
  }

  changeMemberState(data: any) {
    let state = data.actionType;
    return this.http.put(environment.apiBaseUrl + `/${state}Member`, data.loginEmail)
  }

  updateMemberData(body: any) {
    return this.http.put(environment.apiBaseUrl + '/UpdateMember', body)
  }

  getDownloadData(start: any, end: any) {
    let reqData: any = {
      startData: start,
      endData: end
    }
    const headers = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify(reqData));
    return this.http.get(environment.apiBaseUrl + '/GetDownloadData', {headers})
  }

  getStatisticsData(start: any, end: any) {
    let reqData: any = {
      startData: start,
      endData: end
    }
    const headers = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify(reqData));
    return this.http.get(environment.apiBaseUrl + '/GetStatisticsData', {headers})
  }

  getMembersListUpdateListener$() {
    return this.fetchingMembers.asObservable();
  }

  getMembersCountListener$() {
    return this.fetchingMemberCount.asObservable();
  }

  updateJobsListPagination({pageSize, pageIndex}: any) {
    this._jobsListPaginationData.next({pageSize, pageIndex});
  }

  getTotalInvoiceData(start: any, end: any) {
    let reqData: any = {
      startData: start,
      endData: end
    }
    const headers = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify(reqData));
    return this.http.get(environment.apiBaseUrl + '/GetInvoiceTotals', {headers})
  }
}

