import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Observable} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {AddProcessorComponent} from '../add-processor/add-processor.component'
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import * as _ from "lodash";
import {SnackbarService} from '../../services/snackbar.service'
import {take} from 'rxjs/operators';
import {ProcessorsService} from "../../services/processors.service";
import {EditProcessorComponent, ServicePermissions} from "../edit-processor/edit-processor.component";

@Component({
  selector: 'processors-list',
  templateUrl: './processors-list.component.html',
  styleUrls: ['./processors-list.component.scss'],
})

export class ProcessorsListComponent implements OnInit, OnDestroy {
  displayedColumns: string[];
  public fetchingProcessors$: Observable<any>;
  public fetchingProcessorsCount$: Observable<any>;
  public isLoading = false;
  private processorSub: Subscription;
  private processorCountSub: Subscription;
  activeProcessorFilter = true;
  dataSource: any;
  paginatorControl: {
    length: number,
    pageSize: number,
    pageSizeOptions: number[]
  };

  constructor(private processorsService: ProcessorsService, private dialog: MatDialog, private snackbarService: SnackbarService) {
    this.displayedColumns = ['Id', 'firstName', 'lastName', 'loginEmail', 'edit'];
    this.fetchingProcessors$ = this.processorsService.getProcessorsListUpdateListener$();
    this.fetchingProcessorsCount$ = this.processorsService.getProcessorsCountListener$();
    this.isLoading = false;
    this.dataSource = [];
    this.paginatorControl = {
      length: 100,
      pageSize: 10,
      pageSizeOptions: [5, 10, 20, 40, 50, 100]
    };
  }

  ngOnInit() {
    this.isLoading = true;
    this.processorsService.getProcessorCountData()
    this.processorCountSub = this.fetchingProcessorsCount$.subscribe(
      (count) => {
        this.paginatorControl.length = count;
      }
    )
    this.processorsService.getProcessors();
    this.isLoading = true;
    this.processorSub = this.fetchingProcessors$.subscribe(
      (processorList) => {
        if (Array.isArray(processorList) == true) {
          this.isLoading = false;
          this.dataSource = processorList;
        }
      }
    )
  }

  paginate(pageEvent: PageEvent) {
    const {pageSize, pageIndex} = pageEvent;
    this.processorsService.updateJobsListPagination({pageSize, pageIndex});
    this.isLoading = true;
    this.processorsService.getProcessors();
  }

  createProcessor() {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open(AddProcessorComponent, dialogConfig);
    dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      this.isLoading = true;
      this.processorsService.getProcessorCountData()
      this.processorsService.getProcessors()
    })
  }

  editProcessor(member: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = member
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(EditProcessorComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(({servicePermissions, invoicingPermissions, costViewingPermissions}: {
      servicePermissions: ServicePermissions,
      invoicingPermissions: boolean,
      costViewingPermissions: boolean
    }) => {
      if (!(_.isEmpty(servicePermissions) || _.isEmpty(invoicingPermissions))) {
        this.dataSource.find((attr: any, i: number) => {
          this.dataSource[i].servicePermissions = servicePermissions;
          this.dataSource[i].invoicingPermissions = invoicingPermissions;
          this.dataSource[i].costViewingPermissions = costViewingPermissions;
        });
      }
    });
  }

  ngOnDestroy() {
    this.processorSub.unsubscribe();
    this.processorCountSub.unsubscribe();
  }
}


